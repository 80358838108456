import theme from '@chakra-ui/theme'
import { mode, Styles } from '@chakra-ui/theme-tools'

const styles: Styles = {
  ...theme.styles,
  global: props => ({
    ...theme.styles.global,
    'html, body': {
      color: mode('teal.800', 'teal.50')(props),
      bg: mode('purple.50', 'teal.800')(props),
    },
  }),
}

export default {
  ...theme,
  styles,
  config: {
    initialColorMode: 'light',
  },
  fonts: {
    heading: 'neue-haas-unica, sans-serif',
    body: 'acumin-pro, sans-serif',
    mono: 'Menlo, monospace',
  },
  colors: {
    ...theme.colors,
    purple: {
      ...theme.colors.purple,
      50: '#F1F1F4',
    },
    teal: {
      50: '#F7FFFD',
      100: '#CBF8EE',
      200: '#AAF1E6',
      300: '#8BEAE3',
      400: '#63D6D7',
      // 500: '#40B0BD', // default
      // 600: '#238099',
      500: '#021C31',
      600: '#030505',
      700: '#0E4C6A',
      800: '#021C31',
      900: '#030505',
    },
    yellow: {
      50: '#fffada',
      100: '#ffefad',
      200: '#ffe57d',
      300: '#ffda4b',
      400: '#ffd01a',
      500: '#e6b700', // default
      600: '#b38e00',
      700: '#806500',
      800: '#4e3d00',
      900: '#1d1400',
    },
    red: {
      50: '#ffe2e5',
      100: '#ffb1b7',
      200: '#ff7f89',
      300: '#ff4d5a', // pink
      400: '#fe1d2b',
      500: '#e50512',
      600: '#b3000d',
      700: '#810008',
      800: '#4f0004',
      900: '#200000',
    },
  },
}
