import React, { useState, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'apollo/ApolloClient'
import * as Sentry from '@sentry/node'
import { ChakraProvider, CSSReset } from '@chakra-ui/core'
import theme from 'ava/chakra/theme'
import { Elements } from '@stripe/react-stripe-js'
import FirebaseClient from 'firebase/FirebaseClient'
import { CurrentUserContext } from 'firebase/hooks'
import 'firebase/auth'
import { CookiesProvider, useCookies } from 'react-cookie'
import { add } from 'date-fns'
import { loadStripe } from '@stripe/stripe-js'
import { useRouter } from 'next/router'

import '../ava/css/layout.css'

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
})

const stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

const AUTH = 'auth'
const AuthCookie = () => {
  const [cookies, setCookies, removeCookie] = useCookies([AUTH])
  const router = useRouter()
  const { query } = router

  const setAuthCookie = user => {
    const userData = {
      id: user.uid,
      email: user.email,
      token: user.xa,
    }
    setCookies(AUTH, JSON.stringify(userData), {
      expires: add(new Date(), { hours: 1 }),
      path: '/',
    })
    window.heap.identify(user.uid)
    window.heap.addUserProperties({ email: user.email })
    Sentry.configureScope(scope => {
      scope.setUser({ userId: user.uid, email: user.email })
    })
  }

  const removeAuthCookie = () => {
    removeCookies(AUTH)
    window.heap.resetIdentity()
    router.push('/')
  }
  useEffect(() => {
    if (FirebaseClient.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        email = decodeURIComponent(query.email)
      }
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }

      FirebaseClient.auth()
        .signInWithEmailLink(email, window.location.href)
        .then(({ user }) => {
          window.localStorage.removeItem('emailForSignIn')
          setAuthCookie(user)
        })
        .catch(error => {
          router.push('/login?error=expired')
        })
    }
  }, [])

  useEffect(() => {
    FirebaseClient.auth().onIdTokenChanged(async user => {
      if (user) {
        // user signed in or token was refreshed
        const idTokenResult = await user.getIdTokenResult()
        const hasuraClaim = idTokenResult.claims['https://hasura.io/jwt/claims']

        if (hasuraClaim) {
          setAuthCookie(user)
        } else {
          const metadataRef = FirebaseClient.database().ref(
            `metadata/${user.uid}/refreshTime`
          )
          metadataRef.on('value', async data => {
            if (!data.exists) {
              return
            }

            const xa = await user.getIdToken(true)
            setAuthCookie({ ...user, xa })
          })
        }
      } else {
        removeCookie(AUTH)
        window.heap.resetIdentity()
      }
    })
  }, [])

  return <div />
}

export default function Stereo({ Component, pageProps }) {
  const apolloClient = useApollo(pageProps.initialApolloState)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    FirebaseClient.auth().onAuthStateChanged(user => setCurrentUser(user))
  }, [])

  return (
    <ApolloProvider client={apolloClient}>
      <CookiesProvider>
        <CurrentUserContext.Provider value={currentUser}>
          <AuthCookie />
          <Elements stripe={stripe}>
            <ChakraProvider theme={theme}>
              <CSSReset />
              <Component {...pageProps} />
            </ChakraProvider>
          </Elements>
        </CurrentUserContext.Provider>
      </CookiesProvider>
    </ApolloProvider>
  )
}
