import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  from,
  ApolloLink,
} from '@apollo/client'
import { useMemo } from 'react'
import FirebaseClient from 'firebase/FirebaseClient'

let apolloClient: ApolloClient<NormalizedCacheObject>
let currentUser

FirebaseClient.auth().onAuthStateChanged(user => {
  currentUser = user
})

const createApolloClient = () => {
  const ssrMode = typeof window === 'undefined'
  const serverURL = ssrMode
    ? process.env.HASURA_GRAPHQL_URL
    : `${window.location.origin}/api/graphql`

  const authMiddleware = new ApolloLink(async (operation, forward) => {
    let token = ''
    const _currentUser = FirebaseClient.auth().currentUser || currentUser

    if (_currentUser) {
      token = await _currentUser.getIdToken()
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : '',
        },
      })
    }

    return forward(operation)
  })

  const httpLink = new HttpLink({
    credentials: 'include',
    uri: serverURL,
  })

  const logLink = new ApolloLink((operation, forward) => {
    console.log(operation)
    return forward(operation)
  })

  return new ApolloClient({
    ssrMode, // Disables forceFetch on the server (so queries are only run once)
    link: from([authMiddleware, httpLink]),
    cache: new InMemoryCache(),
    connectToDevTools: process.env.NODE_ENV != 'production',
  })
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser
 * @param initialState {NormalizedCacheObject}
 */
export default function initializeApollo(initialState = null) {
  if (typeof window === 'undefined') {
    return createApolloClient()
  }

  if (!apolloClient) {
    apolloClient = createApolloClient()
  }

  if (initialState) {
    apolloClient.cache.restore(initialState)
  }

  return apolloClient
}

export const useApollo = initialState => {
  return useMemo(() => initializeApollo(initialState), [initialState])
}
